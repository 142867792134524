/**
 * アプリケーション内の定数関係
 */

export const config = Object.freeze({
  LOGO_DEFAULT: {
    'pc': '/img/logopc.png',
    'sp': '/img/logosp.png'
  },
  LOGO_LOGIN: {
    'pc': '/img/logo_login_pc.png',
    'sp': '/img/logo_login_sp.png'
  },
  FOOTER_COPY: '© 2023 REVIC Global Co., Ltd.',
  FOOTER_LINKS: [
    {title: '運営会社', url: 'https://www.revicglobal.com/'},
    {title: 'サービス約款', url: 'https://www.revicglobal.com/pdf/sslib_service_agreement.pdf'},
    {title: 'プライバシーポリシー', url: 'https://www.revicglobal.com/privacypolicy/'},
  ],
  LSKEYWORD_BK: 'SSLib::Bookmark', // localstorage keywords
  LSKEYWORD_BKUSAGE: 'SSLib::BookmarkUsage',
  LSKEYWORD_RANKING: 'SSLib::Rankings',
  LSKEYWORD_FREQTAGS: 'SSLib::FrequentTags',
  LSKEYWORD_CURRENTUSERTAG: 'SSLib::CurrentUserTag',
  LSKEYWORD_ISCUSTOMLOGIN: 'SSLib::IsCustomLogin',
  LSKEYWORD_PLAYBACKRATE: 'SSLib::PlaybackRate',
  LSKEYWORD_PLAYERSUBTITLE: 'SSLib::PlayerSubtitle',
  SIGNIN_MAX_USERNAME: 128, // カスタムサインイン(login) username maxlen
  SIGNIN_MAX_USERTAG: 128,  // カスタムサインイン(login) usertag maxlen
  SIGNIN_MAX_PASSWORD: 128, // カスタムサインイン(login) password maxlen
  REGEXP_URL: /^[\w_/:%#$&?()~.=+-[\]\r\n]*$/, // urlsafe カスタムサインイン(login) usertag 有効な文字(正規表現)
  PLAYER_ENDED_THRESHOLD: 0.5, // 閾値が小さいと高速再生時にendedが発火しない
  PLAYER_PLAYBACK_RATES: [0.75, 1, 1.25, 1.5, 2],
  CONTENT_NEW_PERIOD: (30 * 24 * 60 * 60), // content newマーク表示期間 sec
  NEWS_NEW_PERIOD: (7 * 24 * 60 * 60), // news newマーク表示期間 sec
  FREQUENT_TAG_MAX: 20, //
  BOOKMARK_MAX: 99, // bookmark保存最大件数
  RECOMMEND_MAX: 20, // 企業おすすめ最大件数
  // STATIC_CONTENT_ORIGIN: 'https://sslib-static.smartskill.com',
  STATIC_CONTENT_ORIGIN: function () {
    if(window.location.hostname === 'sslib.smartskill.com')
      // for production
      return 'https://sslib-static.smartskill.com';
    else
      // for develop
      return 'https://sslib-static-dev.smartskill.com';
  }(),
  STATIC_DASHBOARD_MANUAL: '/prod/manual/dashboard_usage.pdf',
  STATIC_ADMIN_MANUAL: '/prod/manual/admin_manual.pdf',
  RANKING_DIGEST_LIMIT: 8,
  RANKING_LIMIT: 10,
  RANKING_PRELOAD: 100,
  RANKING_RECENT_RANGE_DAY: 30,
  RANKING_TTL_HOUR: 8, //rankingのlocalstorage ttl
  ISCUSTOMLOGIN_TTL_DAY: 30, // SSLib::IsCustomLogin の有効期間
  TAGLIST_QUERYLIMIT: 500,
  DISPLAYTOP_QUERYLIMIT: 100,
  MOVIEFILM_QUERYLIMIT: 200, // moviefilm クエリの取得件数（ここからシュリンク、フィルタされ、MOVIEFILM_LIMITでsliceされる）
  MOVIEFILM_LIMIT: 100, // moviefilm component の最大表示数
  SEARCH_LIMIT: 200, // 検索結果取得数
  NKCNBC_CHANNEL_LIMIT: 10, // nkcnbcのトップチャンネル取得件数
  NKCNBC_TITLE_LIMIT: 10, // nkcnbcのチャンネル内タイトル取得件数
  NEWS_TOP_ARTICLES_LEN: 10, // トップニュースの件数
  NEWS_LIST_QUERYLIMIT: 100,
  NEWS_TITLE_LEN: 100, // ニュースのタイトル文字数
  NEWS_CONTENT_LEN: 1000, // ニュースのコンテンツ文字数
  NEWS_DEFAULT_DISPLAY: (30 * 24 * 60 * 60), // デフォルトのニュース表示期間 sec
  ADMIN_RECOMMEND_QUERYLIMIT: 500,
  ADMIN_NEWS_QUERYLIMIT: 500,
  ADMIN_DASHBOARD_TOKENSALT: 'D-6^yT)9ZGR.H4WjNFkt+NJNthZ%-D,!yB,BJF:FL.!_#%s7',
  ADMIN_DASHBOARD_TOKENTTL: 300, // ダッシュボードURL生成tokenの有効期限 sec
});
