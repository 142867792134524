import { useStore } from 'vuex';

/* 権限 @cognito groups */
export const AuthGroup: { [key: string]: string } = {
  SYSADMIN: 'sysadmin',
  CORPADMIN: 'corpadmin',
  USERS: 'users'
};

/* device routes */
export const DeviceRoutes: { [key: string]: string } = {
  PC: '/pc',
  SP: '/sp'
};

/* isMobile */
export const isMobile = () => {
  const nav = navigator;
  // chromium対応
  // if('userAgentData' in nav) {
  //   //@ts-ignore
  //   return nav.userAgentData.mobile;
  // } else {
    //return nav.userAgent.match(/iPhone|Android.+Mobile/);
    return /iPhone|Android.+Mobile/.test(nav.userAgent);
  // }
};

export const deviceRoutePrefix = (isMobile() ? DeviceRoutes.SP: DeviceRoutes.PC);

// assert undefined object
export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(
      `Expected 'val' to be defined, but received ${val}`
    );
  }
}  

// generate AWSTimestamp
export const awsNow = () => {
  return Math.round(new Date().getTime() / 1000);
};

// format timestamp
export const formatAwsTimestamp = (awstimestamp:number|undefined, ja = false, time = false) => {
  if (awstimestamp === undefined) return '';
  
  const d = new Date(awstimestamp * 1000);

  // 数値を2桁の文字列にするヘルパー関数
  const zeroPad = (num:number) => num.toString().padStart(2, '0');

  // 日付の文字列を作成
  let fmtstr = ja ? `${d.getFullYear()}年${zeroPad(d.getMonth()+1)}月${zeroPad(d.getDate())}日`
                  : `${d.getFullYear()}/${zeroPad(d.getMonth()+1)}/${zeroPad(d.getDate())}`;

  // 時刻を含める場合は、時刻の部分も文字列に追加
  if (time) {
    // const timeStr = ja ? `${zeroPad(d.getHours())}時${zeroPad(d.getMinutes())}分${zeroPad(d.getSeconds())}秒`
    //                    : `${zeroPad(d.getHours())}:${zeroPad(d.getMinutes())}:${zeroPad(d.getSeconds())}`;
    const timeStr = ja ? `${zeroPad(d.getHours())}時${zeroPad(d.getMinutes())}分`
                       : `${zeroPad(d.getHours())}:${zeroPad(d.getMinutes())}`;
    fmtstr += ja ? ` ${timeStr}` : ` ${timeStr}`;
  }

  return fmtstr;
};

// パスがデバイス解決されているか
export const isDeviceResolved = (path: string) => {
  return Object.keys(DeviceRoutes)
    .map(v => path.startsWith(`${DeviceRoutes[v]}/`))
    .reduce((acc, cur)=>acc||cur);
};

// 解決済みデバイスパス取得
export const resolvedDevicePath = (path: string) => {
  return Object.keys(DeviceRoutes)
    .map(v => (path.startsWith(`${DeviceRoutes[v]}/`) ? DeviceRoutes[v]: ''))
    .reduce((acc, cur) => (cur !== '' ? cur: acc));
};

// Dateを yyyy-mm-dd の文字列に整形
export const formatDate = (src: Date) => {
  return `${src.getFullYear().toString()}-${(src.getMonth()+1).toString().padStart(2, '0')}-${src.getDate().toString().padStart(2, '0')}`;
};

// Dateを yyyymm の文字列に整形
export const formatYYYYMM = (src: Date) => {
  return `${src.getFullYear().toString()}${(src.getMonth()+1).toString().padStart(2, '0')}`;
};

// secを h:mm:ss の文字列に整形
export const formatSec = (sec: string|null) => {
  return (sec === null) ? '': `${(parseInt(sec)/3600)|0}:${((parseInt(sec)%3600/60)|0).toString().padStart(2,'0')}:${(parseInt(sec)%60|0).toString().padStart(2,'0')}`;
  // return `${(sec/3600)|0}:${((sec%3600/60)|0).toString().padStart(2,'0')}:${(sec%60|0).toString().padStart(2,'0')}`;
};

// sec を m分 の文字列に成形
export const formatSecJp = (sec: string|null) => {
  return (sec === null) ? '': `${Math.ceil(parseInt(sec)/60)}分`;
  // return `${Math.ceil(sec/60)}分`;
};

// string の sha2 digest
export const sha256 = async (str: string) => {
  //@ts-ignore Convert string to ArrayBuffer
  const buff = new Uint8Array([].map.call(str, (c) => c.charCodeAt(0))).buffer;
  // Calculate digest
  const dg = await crypto.subtle.digest('SHA-256', buff);
  // Convert ArrayBuffer to hex string
  // (from: https://stackoverflow.com/a/40031979)
  //@ts-ignore
  return [].map.call(new Uint8Array(dg), x => ('00' + x.toString(16)).slice(-2)).join('');
};

// postmessageのイベントハンドラ
export const msgHandler: {[key:string]: any} = {
  'setHeight': (evt: any, obj: any) => {
    obj.height.value = parseInt(evt.data.params.height);
  },
  'setScrollTop': (evt: any, obj: any = null) => {
    window.scrollTo({
      top: parseInt(evt.data.params.scrollTop),
      left: 0,
      behavior: 'smooth'
    });
  },
  'requestMessage': (evt: any, obj: any) => {
    if(evt.data.params.target === 'user') {
      evt.source.postMessage({ user: {
        username: obj.user.value.username,
        userTag: obj.user.value.userTag,
        company: obj.user.value.company
      }}, evt.origin);
    }
  }
};

// ログインテスト(共通)
export const logintest = (init:any, clear:any, refs:any) => {
  const store = useStore();
  store.subscribe(async (mutation, state) => {
    switch (mutation.type) {
      case 'setUser':
        refs.user.value = state.user;
        refs.state.value = state.loggedIn;
        document.body.classList.remove('t-login');
        init();
        break;
      case 'removeUser':
        refs.user.value = state.user;
        refs.state.value = state.loggedIn;
        document.body.classList.add('t-login');
        clear();
        break;
      default:
    }
  });
  if(store.state.loggedIn) {
    refs.user.value = store.state.user;
    refs.state.value = store.state.loggedIn;
    document.body.classList.remove('t-login');
    init();
  }
};
