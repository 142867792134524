import { createStore } from 'vuex';
import { User, Company } from '@/@types/d';
import { config } from '@/config';

export default createStore({
  state: {
    loggedIn: false,
    user: <User>{},
    company: <Company>{}
  },
  getters: {
    user: state => state.user,
    company: state => state.company
  },
  mutations: {
    setUser: (state, user:User) => {
      if(user.userTag) {
        localStorage.setItem(config.LSKEYWORD_CURRENTUSERTAG, user.userTag);
      } else {
        const ut = localStorage.getItem(config.LSKEYWORD_CURRENTUSERTAG) || '';
        user.userTag = ut;
      }
      if(user.userTag == '') {
        localStorage.removeItem(config.LSKEYWORD_ISCUSTOMLOGIN);
      } else {
        localStorage.setItem(config.LSKEYWORD_ISCUSTOMLOGIN, ((Date.now()) + (config.ISCUSTOMLOGIN_TTL_DAY * 24 * 60 * 60 * 1000)).toString());
      }

      state.loggedIn = true;
      state.user = user;
      // console.log(user);
    },
    removeUser: (state) => {
      state.loggedIn = false;
      state.user = {};
      state.company = {};
      localStorage.removeItem(config.LSKEYWORD_CURRENTUSERTAG);
    },
    setCompany: (state, company:Company) => {
      state.company = company;
    }
  },
  actions: {
  },
  modules: {
  }
});
