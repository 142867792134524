import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { deviceRoutePrefix } from '@/lib/common';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(`@/views${deviceRoutePrefix}/App.vue`),
    children: [
      {
        path: 'home',
        alias: '',
        component: () => import(`@/views${deviceRoutePrefix}/HomeView.vue`),
      },
      {
        // 講座詳細
        path: 'course/:id/:clipid',
        alias: 'course/:id',
        props: true,
        component: () => import(`@/views${deviceRoutePrefix}/CourseView.vue`)
      },
      {
        // NkCnbcプレイヤー
        path: 'nkcnbc/:chid/:titleid',
        alias: 'nkcnbc/:chid',
        props: true,
        component: () => import(`@/views${deviceRoutePrefix}/NkCnbcView.vue`)
      },
      {
        // 検索結果リスト
        path: 'search/:query',
        alias: 'search',
        props: true,
        component: () => import(`@/views${deviceRoutePrefix}/SearchView.vue`)
      },
      {
        // カテゴリ(タグ)コンテンツリスト
        path: 'tag/:tagtype/:id',
        alias: [
          'tag',
          'tag/:tagtype'
        ],
        props: true,
        component: () => import(`@/views${deviceRoutePrefix}/TagView.vue`)
      },
      {
        // ランキング
        path: 'ranking/:tagid',
        alias: 'ranking',
        props: true,
        component: () => import(`@/views${deviceRoutePrefix}/RankingView.vue`)
      },
      {
        // ブックマーク
        path: 'bookmark',
        component: () => import(`@/views${deviceRoutePrefix}/BookmarkView.vue`)
      },
      {
        // お知らせ
        path: 'news',
        component: () => import(`@/views${deviceRoutePrefix}/NewsView.vue`)
      },
      {
        // カスタムページ
        path: 'cp',
        component: () => import(`@/views/pc/CustomPageView.vue`)
      },
      {
        path: 'a/',
        component: () => import(`@/views${deviceRoutePrefix}/a/MenuApp.vue`),
        children: [
          {
            path: 'dashboard',
            alias: '',
            component: () => import(`@/views${deviceRoutePrefix}/a/DashboardView.vue`),
          },
          {
            path: 'news',
            component: () => import(`@/views${deviceRoutePrefix}/a/NewsView.vue`),
          },
          {
            path: 'recommend',
            component: () => import(`@/views${deviceRoutePrefix}/a/RecommendView.vue`),
          },
          {
            path: 'admin',
            component: () => import(`@/views${deviceRoutePrefix}/a/AdminView.vue`),
          }
        ]
      },
    ]
  },
  {
    path: '/login',
    component: () => import(`@/views${deviceRoutePrefix}/SignInWithTag.vue`),
  },
  {
    // SSO
    path: '/signin/:cc/:ui/:k',
    props: true,
    component: () => import(`@/views/SignInView.vue`)
  },
  /**
   * NOT FOUND (catch all)
   */
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import(`@/views/PageNotExists.vue`),
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/404',
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(deviceRoutePrefix),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 } // always scroll to top
  },
  routes
});

export default router;
