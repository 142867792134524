import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import AmplifyVue from '@aws-amplify/ui-vue';
import VueElementLoading from "vue-element-loading";

Amplify.configure(awsExports);
createApp(App)
  .use(AmplifyVue).use(store).use(router)
  .component('VueElementLoading', VueElementLoading)
  .mount('#app');
